import React from "react";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Cta from "../components/sections/Cta";

import HeroFull from "../components/sections/HeroFull";

class Home extends React.Component {
  state = {
    videoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" />
        <FeaturesTiles />
        <FeaturesSplit
          invertMobile
          imageFill
          className="illustration-section-05"
        />
        <Cta topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}

export default Home;
